/* background */

* {
  box-sizing: border-box;
}

html {
  font-family: 'Nanum Myeongjo', serif;
  
}

.background {
  background-color: #330000;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin: 0px;
  top: 0;
  left: 0;
}
.heading__wrapper {
  /* position:absolute; */
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

}
.heading__container {
  position: fixed;
  text-align: center;
  top: 200px;
  /* left: 120px; */
}
/* Heading selector */
.heading {
  font-family: 'Nanum Myeongjo', serif;
  /* font-family: inherit; */
  color: #ffcc99;
  /* font-size: 48pt; */
  /* font-size: calc(100% + 3.5vw); */
  font-size: 5vw;
  font-weight: 400;
  /* line-height: initial; */
  white-space: nowrap;
}

.sub_heading {
  font-family: 'Josefin Sans', sans-serif;
  color: #ffcc99;
  /* font-size: 60pt; */
  font-size: 7.5vw;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 60px;
  white-space: nowrap;
}

/* Contact Form button */
.contactButton {
  align-items: center;
  background-color: #fff2e6;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #330000;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.contactButton:hover,
.contactButton:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.contactButton:hover {
  transform: translateY(-1px);
}

.contactButton:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}



@media screen and (max-width: 600px) {
  .sub_heading {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}